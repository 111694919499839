@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

html {
  background-color: rgba(0, 0, 0, 0.55) !important;
}

body {
  background-color: transparent !important;
  display: flex;
}

#pneuma-widget-main-app {
  border: none;
  /* z-index: 2147483647; */
  z-index: 1;
  min-height: 670px;
  height: calc(670px - 50px);
  visibility: visible;
  background: rgb(255, 255, 255);
  opacity: 1;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 16%) 0px 8px 16px;
  pointer-events: auto;
  touch-action: auto;
  top: 0;
  left: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 900px;
}

.app-container {
  height: 100%;
  padding-top: 0px;
  width: 100%;
  overflow: scroll;
}

.app-header {
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px;
  border-radius: 10px 10px 0px 0px;
}

.app-header p {
  margin-bottom: 0px;
}

.app-header img.logo {
  height: 40px;
  width: 100px;
  object-fit: contain;
}

.app-header img.cancel {
  width: 15px;
  cursor: pointer;
}

.app-body {
  /* overflow: hidden; */
  height: calc(670px - 50px);
  /* height: calc(100vh - 50px); */
}

.pb-40 {
  padding-bottom: 40px;
}

/* Medications Modal */

.medications-modal.ant-modal {
  padding: 0px;
  width: 800px !important;
}

.medications-modal.main-modal.ant-modal .ant-modal-header {
  padding: 22px 30px 22px;
}

.medications-select-option.ant-select-item {
  padding: 0px;
}

.medications-select-option .ant-select-item-option-content {
  white-space: break-spaces;
  padding: 5px 12px;
  border-bottom: 1px solid #f2f2f2;
  text-align: left;
  font: normal normal normal;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0px;
  opacity: 1;
}

/* Investigations Modal */

.investigations-modal.ant-modal {
  padding: 0px;
  width: 800px !important;
}

.investigations-modal.main-modal.ant-modal .ant-modal-header {
  padding: 22px 30px 22px;
}

/* Examinations Modal */

.examination-modal.ant-modal {
  padding: 0px;
  width: 676px !important;
}

/* General modal */

.main-modal.ant-modal .ant-modal-content {
  border-radius: 10px;
  border: 1px solid #707070;
}

.main-modal.ant-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
  padding: 22px 51px 22px;
  border-bottom: 1px solid #002e4640;
}

.main-modal.ant-modal .ant-modal-title {
  text-align: left;
  font: normal normal medium 18px/22px DM Sans;
  font-weight: 400;
  letter-spacing: 0px;
  color: #002e46;
  opacity: 0.75;
}

.main-modal .ant-modal-body {
  padding: 0px !important;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
}

.main-modal.ant-modal .ant-modal-footer {
  padding: 22px 51px 22px;
  border-radius: 0px 0px 10px 10px;
  border-top: 1px solid #002e4640;
}

.main-modal.ant-modal .ant-modal-footer .ant-btn {
  padding: 10px 20px;
  height: auto;
  border-radius: 6px;
}

/* Remove Broswer Autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Popover Styles */
.password-hints-popover.ant-popover .ant-popover-content .ant-popover-inner {
  background-color: #e0eefb;
  box-shadow: 0px 1px 3px #002e4640;
  border-radius: 5px;
  text-align: left;
  font: normal normal normal 12px/13px DM Sans;
  letter-spacing: 0px;
  color: #002e46;
}

.password-hints-popover.ant-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: #e0eefb;
  border-bottom-color: #e0eefb;
}

.password-hints-popover.ant-popover
  .ant-popover-content
  .ant-popover-inner
  p.title {
  font-weight: bold;
}

/* Chat More menu */
.more-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font: normal normal medium 11px/9px DM Sans;
  letter-spacing: 0px;
  color: #002e46d9;
  opacity: 1;
}

.more-menu .ant-dropdown-menu-item.end-chat {
  color: #ff2366;
}

.table-heading {
  padding-left: 34px;
  font-weight: normal !important;
}

.chart-wrapper-row {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.chart-value-indicator {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.toggle-table {
  width: 5.5rem !important;
}

.button-top {
  margin-top: 40px;
}

.chart-button-top {
  top: -5px;
}

.service-row-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ant-tabs-tab {
  color: #002e46 !important;
  font-family: 'DM Sans', sans-serif !important;
  font-size: 13px !important;
}

.ant-tabs-nav-wrap {
  margin-left: 0px !important;
}

.ant-tabs-tab:first-child {
  margin-left: 10px !important;
}

.ant-tabs-tab:hover {
  color: #0078e2 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0078e2 !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 2px solid #f0f0f0 !important;
}

.profile-form-margin {
  margin-top: 12px !important;
  margin-bottom: 24px !important;
}

.todo-margin {
  margin-bottom: 13px !important;
}

.todo-checkbox {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 !important;
  color: #4e667e !important;
  opacity: 0.75;
  font-family: 'DM Sans' !important;
  font-size: 13px !important;
}

.todo-checkbox:hover {
  color: unset !important;
}

.todo-end {
  text-align: end;
}

.todo-top {
  margin-top: 2px;
}

.all-encounters-row {
  margin-top: 20px;
  margin-left: -3px;
}

.ant-card.ant-card-bordered.blue {
  background: #f2f7fb80;
}

.light-blue {
  border-left: 5px solid #0078e2 !important;
}

#pneumacare-widget-error {
  padding: 20px;
  height: calc(670px - 50px);
  font-family: 'DM Sans', sans-serif !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pneumacare-widget-error .content h3 {
  font-size: 21px;
  margin-top: 15px;
  margin-bottom: 2px;
}

#pneumacare-widget-error .content p {
  font-size: 15px;
  font-weight: 300;
  margin-top: 0px;
  color: #919191;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* swal button  */
button.swal2-styled.swal2-confirm {
  font-size: 1.3em;
  padding: 10px 24px;
}
